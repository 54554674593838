body {
  overflow: auto !important;
  padding-right: 0px !important;
}

.pac-container {
  z-index: 99999 !important;
}

.react-datepicker-popper {
  z-index: 1001 !important;
  min-width: 293px !important;
}

.form-input-field {
  padding: 0.48rem 1rem !important;
}

.margin-top_1 {
  margin-top: 1.8rem !important;
}
.object-fit-cover {
  object-fit: cover;
}
.top-n5 {
  top: -5px !important;
}
.right-14 {
  right: 14px !important;
}
.custom-solid-btn {
  &-lg {
    padding: 11px 24px !important;
    color: white !important;
    background-color: #816bff !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    // box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    // &:hover {
    //   background-color: #006eff !important;
    //   box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    // }
  }
  &-md {
    padding: 7px 18px !important;
    color: white !important;
    background-color: #816bff !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    // box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    // &:hover {
    //   background-color: #006eff !important;
    //   box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    // }
  }
  &-sm {
    padding: 7px 18px !important ;
    color: white !important;
    background-color: #816bff !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    // box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    // &:hover {
    //   background-color: #006eff !important;
    //   box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    // }
  }
}

// #user-detail-block{
//   display:block !important
// }

.carer-detail-section .card-inner .nav-tabs-card {
  // margin-right: 0px !important;
}
.simplebar-content-wrapper,
.simplebar-scroll-content {
  background-color: white !important;
  position: relative !important;
  z-index: 55;
}

.DSsoU {
  min-height: 48px !important;
}

.carer-table > :not(caption) > * > * {
  padding: 0.5rem 1.5rem 0.5rem 0px !important;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  vertical-align: middle !important;
}
.nk-upload-item .nk-upload-action .btn-icon {
  min-width: 40px;
  min-height: 40px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.carer-table {
  thead tr:last-child th {
    border-top: none !important;
    vertical-align: middle !important;
    &:last-child {
      padding-right: 0px !important;
    }
  }
  tbody tr:has(.text-end) td {
    border-top: none !important;
    vertical-align: middle !important;
    &:last-child {
      padding-right: 0px !important;
    }
  }
  .btn-icon .icon {
    width: 28px !important;
  }
  .btn-icon .icon {
    font-size: 14px !important ;
    line-height: normal !important;
  }
}
.country-list li.country {
  width: "100%" !important;
  display: block !important;

  @media (min-width: 576px) {
    width: "100%" !important;
  }
  @media (min-width: 992px) {
    width: "100%" !important;
  }
}
.nk-block-head {
  position: sticky !important;
  top: 65px !important;
  background-color: #f5f6fa !important;
  padding-bottom: 0.75rem !important;
  z-index: 51;
  padding-top: 12px !important;
}
.nk-content {
  padding-top: 20px !important;
}
.invalid {
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}
.rdt_TableCol .rdt_TableCol_Sortable {
  justify-content: left !important;
  gap: 5px;
}
.rdt_TableHeadRow {
  background-color: #1dce44 !important;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.fs-12 {
  font-size: 12px !important;
}
.overflow-visiable {
  overflow: visible !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.w-68 {
  width: 68px !important;
}
.h-100vh_-_64px {
  height: calc(100vh - 64px) !important;
}
.min-h-300 {
  min-height: 300px !important;
}
.move-right {
  right: 10px !important;
  transform: translate(10px, 10px) !important;
}

// contact-input---------->
.country-list {
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  width: 200px !important;
}
@media (min-width: 576px) {
  .country-list li {
    width: 100% !important;
  }
}
.rdt_Table {
  overflow-x: auto;
}
.rdt_TableRow {
  min-height: 48px !important;
  font-size: 14px !important;
  &:hover {
    background: #f8f9fc !important;
    box-shadow: 0 2px 15px -4px rgba(44, 117, 255, 0.4) !important;
  }
  &:last-child {
    border-radius: 10px;
  }
}

.custom-editor-container {
  width: 100%; /* Set the width as desired, e.g., 100% */
  height: 200px; /* Set the height as desired, e.g., 300px */
}

.spinner-align-center {
  min-height: calc(100vh - 364px) !important;
  align-items: center;
}

.placeholder-style input::placeholder {
  /* Your placeholder styles here */
  color: #526484; /* Change the color to your desired color */
  text-align: center;
}

.min {
  &-h {
    &-120 {
      min-height: 120px !important;
    }
  }
}
.min-w-10 {
  min-width: 100px;
}
.react-select__indicator-separator {
  display: none !important;
}
.react-select__dropdown-indicator {
  display: none !important;
}
.react-select__clear-indicator {
  margin-right: 20px;
}
.css-tj5bde-Svg {
  color: grey;
}

.w-313 {
  width: 313px !important;
}
// modal-----------
.modal-title {
  width: 100% !important;
}

.w-288 {
  width: 315px !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-none {
  cursor: no-drop !important;
}
.badge-grade {
  padding: 0.35rem 1rem;
  font-size: 0.8rem;
}
// dropdowm--------------------------->
.react-select__single-value {
  font-family: "Poppins", sans-serif !important;
}

// job-list-table=================>
.job-list-table {
  .rdt_Table {
    row-gap: 10px !important;
    border: none !important;
    background: none !important;
    // padding: 0 4px !important;
    .rdt_TableHeadRow {
      border-radius: 5px !important;
    }
    .rdt_TableBody {
      row-gap: 10px !important;
      .rdt_TableRow {
        border-radius: 5px !important;
        border: none !important;
      }
    }
  }
}

.active-tab {
  background-color: #1a7dff; /* Active tab background color */
  text-align: center;
  margin-right: 2px;
  color: white;
}

.pending-tab {
  background-color: lightgray; /* Pending tab background color */
  text-align: center;
  margin-right: 2px;
  color: black;
}
.pending-tab-client-detail {
  background-color: #ffffff;
  text-align: center;
  color: black;
  cursor: pointer;
}
.previous-tab {
  background-color: #5296ef; /* Lighter shade of blue for previous tab background */
  text-align: center;
  margin-right: 2px;
  color: white;
}

.active-tab-carer {
  text-align: center;
  background-color: #7a5bf3; /* Active tab background color */
  color: white;
  border-radius: 4px;
  margin-bottom: 2px;
}

.font-size-nav {
  font-weight: 500;
}
.nav-tabs .nav-link.active:after {
  opacity: 0 !important;
}

.nav-tabs .nav-item {
  padding: 0 20px !important;
}

.details-css > div:first-child.card-inner {
  padding: 0 !important;
}

.template-detail .card-inner {
  padding: 0 !important;
}

.padding-template {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.border-card-end {
  border-bottom: 1px solid #dbdfea; /* Adjust the border properties as needed */
}
.timesheet-border-card-end {
  border-bottom: 1px solid #dbdfea; /* Adjust the border properties as needed */
  padding-bottom: 10px; /* Adjust the padding to create some space between the text and the border */
}

.job-detail-title {
  font-weight: 500;
  // width: auto;
  // margin: 0;
  font-size: 20px;
  color: #8094ae;
}
.custom-underline {
  &:hover {
    text-decoration: underline;
    text-decoration-color: #95baff;
  }
}
.mb-28 {
  margin-bottom: 28px !important;
}

.w-trust-icon {
  width: 31px;
  height: 30px;
  flex-shrink: 0;
  background: #e5e9f2;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.job-detail-height {
  height: 40px !important;
}

.vertical-line {
  border-right: 1px solid lightgrey;
}

.user-avatar-size {
  width: 65px !important;
  height: 65px !important;
  font-size: 20px !important;
}

.user-avatar-width {
  width: 100px !important;
  height: 100px !important;
  font-size: 36px !important;
  // color: #fff;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.useravatar-text {
  font-size: 20px;
}

.client-list-table {
  .rdt_Table {
    row-gap: 10px !important;
    border: none !important;
    background: none !important;
    padding: 0 4px !important;
    .rdt_TableBody {
      row-gap: 10px !important;
      .rdt_TableRow {
        border-radius: 4px !important;
        border: none !important;
        min-height: 140px !important;
      }
    }
  }
}
.matrix-table .card-inner-group .card-inner:not(:last-child) .nk-tb-col {
  border-bottom: 0px;
}
.border-light-gray-right {
  border-right: 1px solid #dbdfea;
  border-bottom: 1px solid #dbdfea;
  width: 160px !important;
}

.filter-div {
  width: 320px;
  // background-color: #1676fb;
}
.w-160 {
  width: 160px !important;
}
.rota-matrix-div {
  width: calc(100% - 175px) !important;
  // background-color: #e85347;
}
.height-matrix-card {
  height: calc(100vh - 255px) !important;
}
.carer_client_filter {
  height: calc(100% - 93px) !important;
  overflow-y: auto !important;
}
.carer-filter-height {
  height: 100% !important;
}
.row-carer-filter {
  height: 100% !important;
  width: 320px !important;
}
.height-card_inner {
  height: 100% !important;
}
.rota-inner-card .card-inner {
  height: 100% !important;
}
.buttongrp-carer {
  width: 100% !important;
}

.rota-matrix-calendar .fc {
  height: 100% !important;
}

.carer-profile-photo {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #f4f4f4;
  width: 135px;
  height: 90px;
}
.client-table-name {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.userHomeOrHospitalIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 90px;
  border-radius: 15px;
  background: #f4f4f4;
  flex-shrink: 0;
}
.clienType_icon {
  width: 56px;
  height: 56px;
}
.width-profile {
  width: 100px !important;
}
.matrix-text-card {
  border-radius: 10px;
  // width: 150px;
  padding: 10px;
  text-align: left;
  margin-bottom: 6px;
}
.matrix-font-day {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.matrix-font-date {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.border-border {
  border-left: 4px solid;
  margin-right: 10px;
}
.matrix-table #scroll-matrix::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}
.matrix-table #scroll-matrix::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
.matrix-table #scroll-matrix::-webkit-scrollbar-thumb {
  background-color: #c0c3cc;
}
#custome-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}
#custome-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}
#custome-scroll::-webkit-scrollbar-thumb {
  background-color: #c0c3cc;
}
.active-tab-carer-detail {
  background-color: #09c2de; /* Active tab background color */
  text-align: center;
  margin-right: 2px;
  color: white;
}
.active-tab-client-detail {
  background-color: #7a5bf3;
  text-align: center;
  color: white;
  cursor: pointer;
}
.matrix-card-align-top .nk-tb-col {
  vertical-align: top;
}
.client-data-table-height .rdt_TableRow {
  max-height: 140px !important;
}
.carer-profile-section {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carer-image-css {
  border-radius: 50px;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
}

.carer-image-for-rota {
  width: 50px;
  height: 51px;
  border-radius: 50px;
}

.min-width-carer-detail-profile {
  min-width: 320px !important;
}

.padding-0 .card-inner {
  padding: 0%;
}

.carer-border-divide {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dbdfea;
}
.padding-carer-data {
  padding-left: 40px;
}
.margin-client-form-save {
  margin-top: 12px;
}
.w-320px {
  width: 320px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e0e0e0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  // left: 4px;
  // bottom: 4px;
  background-color: #1dce44;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider:before {
  background-color: #816bff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.slider-rotas {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(243, 241, 241);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-rotas:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  // left: 4px;
  // bottom: 4px;
  background-color: #1dce44;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-rotas:before {
  background-color: #816bff;
}

input:checked + .slider-rotas:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider-rotas.round {
  border-radius: 34px;
}

.slider-rotas.round:before {
  border-radius: 50%;
}
.call-email-icon {
  color: #666666;
  font-size: 16px;
}
.timesheet-row {
  background-color: #1dce44;
  border-radius: 5px 5px 0px 0px;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 -17px;
}
.timesheet-col-text {
  font-size: 14px;
  text-decoration: #222;
}
.timesheet-row-text {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  background: #fff;
}
.timesheet-block-title {
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.w-100px {
  width: 100px;
}
.w-120px {
  width: 120px;
}
.padding-data-item-timesheet {
  padding: 0rem 1rem !important;
  min-height: 40px !important;
}

@media (max-width: 767px) {
  .value-height-fix {
    min-height: 38px !important;
  }
}

.timesheet-row-min-height {
  min-height: 450px !important;
}
.min-width-timesheet {
  min-width: 575px !important;
}
.text-status-timesheet-badge {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hospitalbadge {
  border-radius: 20px;
  background: #09c2de !important;
  width: 100px;
  height: 22px;
  justify-content: center;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}
.carehomebadge {
  border-radius: 20px;
  background: #1ee0ac !important;
  justify-content: center;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}
.client-detail-profile-title {
  color: #333;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.clientdetail-trustname {
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.clientdetail-shortline {
  width: 100%; /* Adjust the width as needed */
  height: 1px;
  background: #e8e8e8;
  display: flex;
  justify-content: center;
  margin: 10px 0; /* Adjust margin as needed */
}
.referenceid-text {
  color: #666;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.carerdetails-tab-head {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.carerdetails-tab-text {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.padding-important {
  padding: 5px !important;
}

.job-edit-icon {
  background: url("../images/jsTree/job/editicon.svg"), lightgray 50% / cover no-repeat;
  width: 24px;
  height: 20.308px;
  flex-shrink: 0;
}

.clientdetail-timesheet {
  .rdt_TableRow {
    height: 80px;
    color: #222 !important;
    font-family: Poppins;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
  }
}

.tableDatalistingText {
  .rdt_TableRow {
    color: #222 !important;
    font-family: Poppins;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
  }
}

.listingsubtext {
  color: #666;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.referenceid-text {
  color: #666;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.carerdetails-tab-head {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.carerdetails-tab-text {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.fs-poppins {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.carerdetails-dob-head {
  color: #666;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.carerdetails-dob-text {
  color: #222;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.carerdetails-otherdetails-head {
  color: #666;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.carerdetails-otherdetails-text {
  color: #222;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sign-box-css {
  width: 152px;
  height: 52px;
  flex-shrink: 0;
}
.project-head {
  margin-bottom: -5px !important;
}
.timesheet-formtimepicker-height {
  width: 100px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #333;
  background: #fff;
}
.display-flex-column {
  display: flex;
  flex-direction: column;
}
.display-flex-row {
  display: flex;
  flex-direction: row;
}
.h-50px {
  height: 50px;
}
.h-100px {
  height: 100px;
}
.total-hour-box {
  height: 36px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #1dce44;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timesheet-data-header-box-size {
  display: flex;
  color: #ffffff;
  align-items: center !important;
}
.every-timesheet-date-header {
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.timesheet-sub-detail {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hospital-list-table {
  .rdt_Table {
    .rdt_TableBody {
      .rdt_TableRow {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}
// carerdetails documents

.carer-detail-document {
  .rdt_Table {
    row-gap: 10px !important;
    border: none !important;
    background: none !important;
    // padding: 0 4px !important;
    .rdt_TableHeadRow {
      border-radius: 5px !important;
    }
    .rdt_TableBody {
      row-gap: 10px !important;
      .rdt_TableRow {
        border-radius: 5px;
        border: 1px solid #ccc;
        background: #fff;
        color: #333;
        height: 60px;
        flex-shrink: 0;
      }
    }
  }
}

.carerdetail-document-statusbadge {
  border-radius: 20px;
  justify-content: center;
}
.min-height-100 {
  min-height: 100% !important;
}
.min-w-25px {
  min-width: 25px;
}

.wage-list-table {
  .rdt_Table {
    row-gap: 10px !important;
    border: none !important;
    background: none !important;
    // padding: 0 4px !important;
    .rdt_TableHeadRow {
      border-radius: 5px !important;
    }
    .rdt_TableBody {
      row-gap: 10px !important;
      .rdt_TableRow {
        border-radius: 5px !important;
        border: none;
      }
    }
  }
}

.wage-list-border {
  .rdt_Table {
    .rdt_TableBody {
      .rdt_TableRow {
        border-radius: 5px !important;
        border: 1px solid #dbdfea !important;
      }
    }
  }
}

.tableWagelistingText {
  .rdt_TableRow {
    color: #222 !important;
    font-family: Poppins;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    padding-top: 19px;
    padding-bottom: 19px;
  }
}

.wage-detail-text-color {
  font-size: 12px;
  color: #555555;
}

.wageClient-text {
  font-size: 14px;
  color: #000000;
}

.timesheet-save-button {
  width: 100px;
  background-color: #1dce44 !important;
  border: none;
}

.timesheet-attach-button {
  // width: 100px;
  background-color: #816bff !important;
  // border: none;
  border-right: 1px solid white !important;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-radius: 4px;
}

.timesheet-attachment-row-height {
  height: 60px;
}
.timeSheetDetailTitle {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.timesheetDetailModalValue {
  font-size: 14px;
  font-weight: normal;
  color: #000000;
}

.timesheetDetailModalLabel {
  font-size: 14px;
  font-weight: normal;
  color: #666666;
}

.timesheetDetailModalLabelForComment {
  flex-wrap: wrap;
  width: 23% !important;
  font-size: 14px;
  font-weight: normal;
  color: #666666;
}

.timesheetDetailModalValueForComment {
  width: 77% !important;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
}

.dotted-line {
  border-top: 1px dotted #000; /* Adjust color and width as needed */
}

.data-col-detailItem {
  display: flex;
  flex-direction: column;
}
.clientDetailModalCss {
  background-color: #1dce44;
  color: white !important;
  // color: #FFFFFF;
  .modal-title {
    color: white !important;
  }
  .btn-close {
    color: white !important;
  }
  .btn-close::after {
    color: white !important;
  }
  //  text-decoration: #7a5bf3;
}

.hospital-detail-tab-button {
  width: 50%;
}

.steps ul li {
  width: 50%;
}

.invoice-list-table {
  .rdt_Table {
    row-gap: 10px !important;
    border: none !important;
    background: none !important;
    // padding: 0 4px !important;
    .rdt_TableHeadRow {
      border-radius: 5px !important;
    }
    .rdt_TableBody {
      row-gap: 10px !important;
      .rdt_TableRow {
        border-radius: 5px !important;
        border: none;
      }
    }
  }
}

//  Tagify CSS
.tagify {
  align-items: center !important;
  padding: 0px !important;
  min-height: 36px;
  border-radius: 4px !important;
}
.dropzone {
  padding: 0px;
  position: relative;
}
.dropzon-button {
  padding: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
}
.tagify__tag > div::before {
  box-shadow: 0 0 0 1.1em #2c75ff26 inset !important;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
  box-shadow: 0 0 0 1.1em rgb(255 0 0 / 25%) inset !important;
}
.tagify__tag-text {
  line-height: 16px !important;
}
.tagify__input:empty {
  display: block !important;
}

.height-44 {
  height: 44px !important;
}

.dashboard-rota {
  .nk-tb-list {
    width: 79% !important ;
  }
}
@media (min-width: 1034px) {
  .dashboard-box-height .card-full {
    height: 522px !important;
    border: 1px solid #dbdfea;
  }
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.rota-head-row {
  background-color: #1dce44;
  color: #ffffff !important;
  border-radius: 4px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 378px; /* Adjust as necessary to fit your design */
}

.spinner-container-statistics {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as necessary to fit your design */
}

.job-detail-fw-bold {
  font-weight: bold;
}

.chartjs-render-monitor {
  cursor: pointer;
}
.w-103px {
  width: 103px !important;
}
.w-350px {
  width: 200px !important;
}

@media (min-width: 1200px) {
  .w-350px {
    width: 330px !important;
  }
}

.w-200px {
  width: 200px !important;
}
.w-166px {
  width: 166px !important;
}
.w-440px {
  width: 440px;
}
.transaction-ui {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  .rdt_TableCol:not(:last-child),
  .rdt_TableCell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .rdt_TableCell {
    padding: 0px !important;
  }
  .form-control {
    border: 1px solid #006eff00 !important;
  }
  .form-control:focus {
    color: #3c4d62;
    background-color: #fff;
    border: 1px solid #006eff !important;
    // border-color: #006eff !important;
    border-radius: 0px;
    outline: 0;
    box-shadow: none !important;
  }
  // .form-control-select {
  //   width: 100%;
  // }
  // .rdt_TableRow {
  //   min-height: 52px !important;
  // }

  // .form-control {
  //   border: 1px solid #006eff00 !important;
  // }
  .form-control:focus {
    color: #3c4d62;
    background-color: #fff;
    border: 1px solid gray !important;
    // border-color: #006eff !important;
    border-radius: 0px;
    outline: 0;
    box-shadow: none !important;
  }
  // .form-control-select {
  //   width: 100%;
  // }
  .rdt_TableRow {
    min-height: 52px !important;
  }

  // // new------------->

  .table-rows .nk-tb-col:not(:last-child),
  .nk-tb-head .nk-tb-col:not(:last-child) {
    border-right: 1px solid #dbdfea !important;
  }

  .table-rows .nk-tb-col:last-child {
    width: 50px !important;
  }
  .table-rows .nk-tb-col {
    padding: 0px !important;
  }
  .css-yk16xz-control,
  .react-select__control {
    border: none !important;
  }

  .wrong-input {
    border: 1px solid #ff0000 !important;
    border-radius: 0px !important;
  }
  .rigth-input {
    border: 1px solid #ff000000 !important;
  }
  .wrong-input:focus {
    border: 1px solid #ff0000 !important;
    border-radius: 0px !important;
  }
}
.h-70px {
  min-height: 80px !important;
}
.terms-heading {
  background-color: #ffffff !important;
  border: 1px solid #dbdfea;
  border-radius: 5px;
}
.faq-card {
  width: 25%;
  margin: 0 auto;
}
.flex-evenly-spaced {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  flex: 1;
  padding: 0 10px; /* Adjust padding as needed */
}

.audit-trail-seprater {
  border-right: 1px solid #dddddd !important;
}
